define('frontend/models/monitored-job', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACTIVE_STATUSES = ['not_yet_started', 'queued', 'running'];

  exports.default = _application.default.extend({
    status: _emberData.default.attr("string"),
    completion: _emberData.default.attr("number"),
    startedAt: _emberData.default.attr('date'),
    endedDate: _emberData.default.attr('date'),
    logOutput: _emberData.default.attr('string'),

    active: Ember.computed('status', function () {
      return ACTIVE_STATUSES.indexOf(this.get("status")) >= 0;
    })
  });
});