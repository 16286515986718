define('frontend/serializers/survey/bulk-survey-edit', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      monitoredJob: { serialize: false }
    },
    serialize: function serialize(snapshot) {
      var result = this._super.apply(this, arguments);
      var model = snapshot.record;

      var serializedItemData = model.getWithDefault("response.responseItems", []).map(function (ri) {
        var data = typeof ri.serialize === 'function' ? ri.serialize().data : ri.content.serialize().data;
        data.id = ri.get("id");
        return data;
      });

      result.data.attributes['item-data'] = serializedItemData;
      return result;
    }
  });
});