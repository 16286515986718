define("frontend/locales/en/provider", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var defaultSymptomScoreNames = {
    unknown: "unknown",
    none: "typical",
    low: "elevated",
    moderate: "elevated",
    severe: "severe",
    high: "high",
    "": ""
  };

  var inverseSymptomScoreNames = {
    unknown: "unknown",
    none: "strong",
    low: "low",
    moderate: "typical",
    severe: "very low",
    high: "high",
    "": ""
  };

  var ptpbThresholdNames = {
    low: "low",
    moderate: "moderate",
    moderateHigh: "medium/high",
    high: "high"
  };

  var mireccGafThresholdNames = {
    dysfunctional: "Dysfunctional",
    dangerous: "Dangerous",
    borderline: "Borderline",
    functional: "Functional",
    unknown: "Unknown"
  };

  exports.default = {
    objectActions: {
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      yesDelete: "Yes, delete",
      yesDischarge: "Yes, discharge",
      noCancel: "No",
      yesCancel: "Yes, cancel",
      ok: "Ok",
      showMore: "more",
      showLess: "less",
      change: "[change]"
    },
    login: {
      navTitle: "Login",
      welcome: "",
      pleaseSignIn: "Welcome, please login to your account.",
      login: "Log In",
      loginPrivateMode: "<span class='fa fa-eye-slash'></span> Log in discreet mode",
      loginPrivateModeDetail: "Log in to discreet screen",
      autoLogout: "Your session has expired after a period of inactivity. Please sign in again.",
      forgottenPassword: "Forgot Password?",
      lastAttempt: "Your account will be locked after one more failed attempt",
      accountLocked: "Your account has been locked due to too many login failures. It will unlock in 1 hour.",
      accountDisabled: "Your account has been disabled. Please contact an administrator.",
      lastLogin: "Last sign in:",
      failed: "We have not been able to verify your details. Please try again, or if the problem persists, contact your administrator."
    },
    inactive: {
      title: "You have been automatically logged out",
      description: "Another tab or window on this computer has logged in with different credentials. You have been automatically logged out."
    },
    landing: {
      title: "Welcome",
      description: ""
    },
    support: {
      navTitle: "Contact Support",
      emailSupport: "We're happy to help.<br> email: support@mirah.com<br>"
    },
    scales: {
      autoplanned: "Scales are calculated automatically",
      manualPlanWarning: "Scales selected manually. Planning will not apply.",
      strengths: "Strengths",
      symptoms: "Symptoms",
      administeredTo: "Administered to",
      measuredDate: "Measured on",
      noParticipants: "There are no participants for this scale",
      adminIntervalText: "Time period asked about",
      estimatedTakeTime: "Average taking time",
      estimatedTakeTimeMins: "mins.",
      adult: "Adult",
      child: "Child & Adolescent",
      coreLabel: "Core",
      supplementalLabel: "Supplemental",
      moreInfo: "more info.",
      trends: {
        changed: "Changed since last visit",
        targeted: "Targeted scales"
      },
      badges: {
        future: "Future",
        sent: "Email sent",
        begun: "In progress",
        complete: "Already completed"
      },
      invalid: "Invalid",
      preview: {
        interviewMode: "Show Interview Mode",
        selfReportMode: "Show Self Report Mode"
      },
      interpretation: {
        low: {
          graphLabel: "low"
        },
        moderate: {
          graphLabel: "moderate"
        },
        medium: {
          graphLabel: "medium"
        },
        high: {
          graphLabel: "high"
        },
        severe: {
          graphLabel: "severe"
        },
        moderatelySevere: {
          graphLabel: "mod. severe"
        },
        clinical: {
          graphLabel: "clinical"
        },
        subclinical: {
          graphLabel: "subclinical"
        },
        typical: {
          graphLabel: "typical"
        },
        mild: {
          graphLabel: "mild"
        },
        none: {
          graphLabel: "none"
        },
        unknown: {
          graphLabel: ""
        },
        continuous: {
          graphLabel: ""
        },
        dysfunctional: {
          graphLabel: "Dysfunctional"
        },
        dangerous: {
          graphLabel: "Dangerous"
        },
        borderline: {
          graphLabel: "Borderline"
        },
        functional: {
          graphLabel: "Functional"
        },
        expected: {
          graphLabel: "expected"
        },
        belowExpected: {
          graphLabel: "below"
        },
        aboveExpected: {
          graphLabel: "above"
        }
      },
      // You need to use ‑ as a non break hyphen to stop e.g. GAD-7 getting linewrapped.
      norse: {
        name: "Computer Adaptive Multi-dimensional scale (CAMS)",
        shortname: "CAMS",
        category: "Multi-dimensional",
        interpretation: defaultSymptomScoreNames,
        description: "Measures a broad range of patient symptoms and strengths to provide an overview of areas of difficulty and patient abilities to recruit in treatment.",
        adminInterval: "prior therapy session."
      },
      alliance: {
        shortname: "Alliance",
        name: "Therapy alliance",
        interpretation: inverseSymptomScoreNames,
        description: "Measures a patient's trust and positive relationship with their therapist."
      },
      attachment: {
        shortname: "Attachment",
        name: "Attachment",
        interpretation: inverseSymptomScoreNames,
        description: "Measures a patient's beliefs about their ability to emotionally trust and connect with others."
      },
      avoidance: {
        shortname: "Avoidance",
        name: "Avoidance",
        interpretation: defaultSymptomScoreNames,
        description: "Measures active avoidant coping to keep from feeling unwanted emotions."
      },
      connectedness: {
        shortname: "Connectedness",
        name: "Connectedness",
        interpretation: inverseSymptomScoreNames,
        description: "Measures the degree to which a patient feels currently connected to and supported by others."
      },
      demoralization: {
        shortname: "Hopelessness",
        name: "Hopelessness",
        interpretation: defaultSymptomScoreNames,
        description: "Measures thoughts of demoralization or lost hope."
      },
      eating: {
        shortname: "Eating",
        name: "Eating Problems",
        interpretation: defaultSymptomScoreNames,
        description: "Measures thoughts and behaviors associated with disordered eating."
      },
      emotionalDistance: {
        shortname: "Distancing",
        name: "Emotional Distancing",
        interpretation: defaultSymptomScoreNames,
        description: "Measures a patient's use of dissociation as a coping mechanism to protect from stressful or emotional events."
      },
      expressedTherapyNeeds: {
        shortname: "Therapy preferences",
        name: "Therapy preferences",
        interpretation: {
          unknown: "unknown",
          none: "satisfied",
          low: "minor issues",
          moderate: "some issues",
          severe: "major issues",
          "": ""
        },
        description: "Measures a patient's satisfaction with the techniques and approach of the clinician in therapy."
      },
      hurtfulRumination: {
        shortname: "Rumination",
        name: "Hurtful Rumination",
        interpretation: defaultSymptomScoreNames,
        description: "Measures the thought processes consistent with anxious and depressed cognitive spirals."
      },
      hypervigilance: {
        shortname: "Hypervigilance",
        name: "Hypervigilance",
        interpretation: defaultSymptomScoreNames,
        description: "Measures attention to safety and surroundings."
      },
      perfectionism: {
        shortname: "Perfectionism",
        name: "Perfectionism and Control",
        interpretation: defaultSymptomScoreNames,
        description: "Measures the need to feel in control as well as the perception of being in control of life currently."
      },
      negativeAffect: {
        shortname: "Negative Affect",
        name: "Pressure from Negative Affect",
        interpretation: defaultSymptomScoreNames,
        description: "Measures general feelings of neurotic distress."
      },
      psychosis: {
        shortname: "Psychosis",
        name: "Psychosis",
        interpretation: defaultSymptomScoreNames,
        description: "Measures odd thinking and seldom-endorsed beliefs."
      },
      recentChanges: {
        shortname: "Events",
        name: "Recent life events",
        interpretation: defaultSymptomScoreNames,
        category: "Life Events",
        description: "Asks the patient events in their lives since the prior visit."
      },
      relationalDistress: {
        shortname: "Relational Distress",
        nanoname: "Relat'l Distress",
        name: "Relational Distress",
        interpretation: defaultSymptomScoreNames,
        description: "Measures conflict and stress due to interpersonal relationships."
      },
      resilience: {
        shortname: "Resilience",
        name: "Resilience and Personal Coping",
        interpretation: inverseSymptomScoreNames,
        description: "Measures a patient's ability to draw on coping mechanisms to deal with difficult events."
      },
      socialRole: {
        shortname: "Social Role",
        name: "Social Role and Function",
        interpretation: inverseSymptomScoreNames,
        description: "Measures a patient's perception of their success filling the roles they currently value in life."
      },
      somaticAnxiety: {
        shortname: "Somatic Anxiety",
        name: "Somatic Anxiety",
        interpretation: defaultSymptomScoreNames,
        description: "Measures physical feelings associated with anxiety and stress."
      },
      substanceUse: {
        shortname: "Substance Use",
        name: "Substance Use",
        interpretation: defaultSymptomScoreNames,
        description: "Measures a patient's concern about their use of substances."
      },
      suicideRisk: {
        shortname: "Suicide",
        name: "Suicide",
        interpretation: defaultSymptomScoreNames,
        description: "Measures level of suicidal ideation, and whether the patient has a plan."
      },
      selfHarm: {
        shortname: "Self-Harm",
        name: "Self-Harm",
        interpretation: defaultSymptomScoreNames,
        description: ""
      },
      therapyAlliance: {
        shortname: "Therapy Alliance",
        name: "Therapy Alliance",
        interpretation: inverseSymptomScoreNames
      },
      medicationThoughts: {
        shortname: "Med. Concerns",
        name: "Medication concerns",
        interpretation: inverseSymptomScoreNames
      },
      therapyPreferences: {
        shortname: "Therapy Preferences",
        name: "Therapy Preferences",
        interpretation: {
          unknown: "unknown",
          none: "satisfied",
          low: "minor issues",
          moderate: "some issues",
          severe: "major issues",
          "": ""
        }
      },
      holisticResponses: {
        shortname: "Other Concerns",
        name: "Free-text general responses",
        category: "Free Text",
        adminInterval: "prior therapy session",
        description: "Open-ended questions on goals, approaches to challenges, and other input"
      },
      recentEvents: {
        shortname: "Recent Events",
        name: "Ask patients for recent life events",
        adminInterval: "prior therapy session",
        description: "Patients will be questioned for recent important life events"
      },
      ptpb: {
        shortname: "Peabody Measure",
        name: "Peabody Treatment Progress Battery",
        category: "Multi-dimensional",
        adminInterval: "prior therapy session",
        description: "The Peabody Treatment Progress Battery (PTPB) is an integrated comprehensive set of 11 assessments measuring outcomes (e.g. symptom severity, life satisfaction) and treatment processes (e.g. therapeutic alliance, counseling impact). The PTPB uses a common factors approach to the measurement of treatment, which allows for the battery to be used with most treatment modalities. Assessments are completed by patients and caregivers. *The PTPB is intended for youth aged 11 to 18 in outpatient treatment settings. However, clinical judgment may indicate use among patients younger than 11 years of age if they have at least a 4th grade level of reading comprehension. Similarly, clinicians may choose to use the measures with patients who reach 18 years of age or beyond in continuing treatment."
      },
      goals: {
        shortname: "Goals",
        name: "Patient-specific goals",
        category: "Goals",
        description: "Tracks progress of custom patient goals and problems",
        adminInterval: "prior therapy session"
      },
      sfss: {
        shortname: "SFSS",
        name: "Symptoms and Functioning Severity Scale (SFSS)- Youth Self Report",
        description: "The SFSS serves as the core indicator of treatment progress in the PTPB. It is a general measure of youths’ emotional and behavior problems. It is a severity measure and is not designed to provide a diagnosis. The SFSS items are based on the four most common mental health disorders for youth: ADHD, conduct/oppositional disorder, depression, and anxiety. This version of the SFSS will administer questions to only the youth for self-report.",
        category: "General",
        interpretation: ptpbThresholdNames,
        graphInterpretation: "The SFSS is a general measure of youths’ emotional and behavior problems.   Caregiver and child scales are graphed normalized to a common axis to better highlight meaningful discrepancies.",
        graphLabel: "Child reported"
      },
      sfssA: {
        shortname: "SFSS A",
        name: "SFSS (A)",
        description: "Contains half of the SFSS items",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssB: {
        shortname: "SFSS B",
        name: "SFSS (B)",
        description: "Contains half of the SFSS items",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssI: {
        shortname: "Internalizing",
        name: "SFSS Internalizing",
        description: "SFSS subscale measuring internal symptoms -- the child's thoughts and feelings",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssE: {
        shortname: "Externalizing",
        name: "SFSS Externalizing",
        description: "SFSS subscale measuring external symtpoms -- the child's behavior",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssSuicide: {
        shortname: "Self-Harm",
        name: "Peabody Self-Harm item",
        description: "Item asking about thoughts of self-harm",
        interpretation: ptpbThresholdNames
      },
      sfssSuicide_C: {
        shortname: "Self-Harm (Cg)",
        name: "Peabody Self-Harm item (Cg)",
        description: "Item asking about thoughts of self-harm",
        interpretation: ptpbThresholdNames
      },
      sfss_C: {
        shortname: "SFSS (Cgvr)",
        nanoname: "SFSS (Cg)",
        name: "Symptoms and Functioning Severity Scale (SFSS)- Caregiver Report",
        description: "The SFSS serves as the core indicator of treatment progress in the PTPB. It is a general measure of youths’ emotional and behavior problems. It is a severity measure and is not designed to provide a diagnosis. The SFSS items are based on the four most common mental health disorders for youth: ADHD, conduct/oppositional disorder, depression, and anxiety. This version of the SFSS will administer questions to only the caregiver.",
        category: "General",
        interpretation: ptpbThresholdNames,
        graphLabel: "Caregiver reported"
      },
      sfssA_C: {
        shortname: "SFSS A (Cgvr)",
        nanoname: "SFSS A (Cg)",
        name: "SFSS (A) Adult Caregiver",
        description: "Contains half of the SFSS items, responded to by an adult caregiver of the patient",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssB_C: {
        shortname: "SFSS B (Cgvr)",
        nanoname: "SFSS B (Cg)",
        name: "SFSS (B) Adult Caregiver",
        description: "Contains half of the SFSS items, responded to by an adult caregiver of the patient",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssI_C: {
        shortname: "Internalizing (Cgvr)",
        nanoname: "Internalizing (Cg)",
        name: "SFSS Internalizing",
        description: "SFSS subscale measuring internal symptoms -- the child's thoughts and feelings",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      sfssE_C: {
        shortname: "Externalizing (Cgvr)",
        nanoname: "Externalizing (Cg)",
        name: "SFSS Externalizing",
        description: "SFSS subscale measuring external symtpoms -- the child's behavior",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      taqs: {
        shortname: "Therapy Alliance",
        name: "Therapeutic Alliance Quality",
        description: "Measures the working alliance between provider and patient",
        category: "Alliance",
        interpretation: ptpbThresholdNames
      },
      ptpbChs: {
        shortname: "Child Hope",
        name: "Child Hope Scale",
        description: "Measures positive goal-oriented thinking",
        category: "Hope",
        interpretation: ptpbThresholdNames
      },
      bmslss: {
        shortname: "Life Satisfaction",
        nanoname: "Life Satisf",
        name: "Student Life Satisfaction Scale",
        description: "Measures perceived quality of life",
        category: "Life Satisfaction",
        interpretation: ptpbThresholdNames
      },
      swls: {
        shortname: "Life Satisfaction (Cgvr)",
        nanoname: "Life Satisf (Cg)",
        name: "Satisfaction with Life (Caregiver)",
        description: "Measure of adult caregiver life satisfaction",
        category: "Life Satisfaction",
        interpretation: ptpbThresholdNames
      },
      sss: {
        shortname: "Service Satisfaction",
        nanoname: "Srv Satisf",
        name: "Service Satisfaction Scale",
        description: "The Service Satisfaction Scale (SSS) is a 4-item measure of patient satisfaction with the treatment they receive. Satisfaction with services represents one type of treatment outcome and thus can be thought of as an indicator of treatment progress. The SSS is recommended to be completed every two months during concurrent treatment, and at discharge.",
        category: "Service Satisfaction",
        interpretation: ptpbThresholdNames
      },
      sss_C: {
        shortname: "Service Satisfaction (Cgvr)",
        nanoname: "Srv Satisf (Cg)",
        name: "Service Satisfaction Scale - Caregiver",
        description: "The Service Satisfaction Scale (SSS) is a 4-item measure of caregiver satisfaction with the treatment their child receives. Satisfaction with services represents one type of treatment outcome and thus can be thought of as an indicator of treatment progress. The SSS is recommended to be completed every two months during concurrent treatment, and at discharge.",
        category: "Service Satisfaction",
        interpretation: ptpbThresholdNames
      },
      myts: {
        shortname: "Motivation",
        name: "Youth Motivation For Treatment",
        description: "Measures youth's extrinsic and intrinsic motivation for treatment",
        category: "Motiviation",
        interpretation: ptpbThresholdNames
      },
      myts_C: {
        shortname: "Motivation (Cgvr)",
        nanoname: "Motivation (Cg)",
        name: "Youth Motivation For Treatment - Caregiver",
        description: "Measures caregiver's extrinsic and intrinsic motivation for youth's treatment",
        category: "Movitation",
        interpretation: ptpbThresholdNames
      },
      ycis: {
        shortname: "Counseling Impact",
        name: "Youth Counseling Impact Scale",
        description: "Measures perceived effectiveness of a specific counseling session",
        category: "Effectiveness",
        interpretation: ptpbThresholdNames
      },
      toes: {
        shortname: "Therapy Expectations",
        nanoname: "Tx Exp",
        name: "Treatment Outcomes Expectations Scale",
        description: "Measures patient expectations for outcomes of treatment",
        category: "Expectations",
        interpretation: ptpbThresholdNames
      },
      toes_C: {
        shortname: "Therapy Expectations (Cgvr)",
        nanoname: "Tx Exp (Cg)",
        name: "Treatment Outcomes Expectations Scale - Caregiver",
        description: "Measures caregiver expectations for outcomes of treatment",
        category: "Expectations",
        interpretation: ptpbThresholdNames
      },
      cgsq: {
        shortname: "Caregiver Strain",
        name: "Caregiver Strain Questionnaire",
        description: "Measures extend to which caregivers and families experience additional demands, responsibilities and difficulties.",
        category: "General",
        interpretation: ptpbThresholdNames
      },
      ptpbMed: {
        shortname: "Meds",
        name: "Medication concerns",
        description: "Asks child about medication taking and impact.",
        interpretation: ptpbThresholdNames
      },
      ptpbMed_C: {
        shortname: "Meds (Cgvr)",
        nanoname: "Meds (Cg)",
        name: "Medication concerns (Caregiver)",
        description: "Asks caregiver about medication taking and impact.",
        interpretation: ptpbThresholdNames
      },
      ptpbMedCombined: {
        shortname: "Medications",
        name: "Medication concerns",
        description: "Asks about medication taking and impact.",
        interpretation: ptpbThresholdNames
      },
      imr: {
        shortname: "IMR - Self Rating",
        name: "Illness Management and Recovery - Self Rating",
        description: "Measures patient's management of their symptoms and life",
        category: "General",
        interpretation: {
          unknown: ""
        }
      },
      imr_C: {
        shortname: "IMR - Practitioner Rating",
        name: "Illness Management and Recovery - Practitioner Rating",
        description: "Measures patient's management of their symptoms and life",
        category: "General",
        interpretation: {
          unknown: ""
        }
      },
      cansas: {
        shortname: "CANSAS",
        name: "Camberwell Assessment of Need - Short Appraisal Schedule (CANSAS)",
        description: "Measures patient's needs and if they are being met",
        category: "General",
        interpretation: {
          unknown: ""
        }
      },
      cansasUnmetNeed: {
        shortname: "CANSAS unmet needs",
        name: "CANSAS unmet needs",
        description: "Measures patient's unmet needs",
        category: "General",
        interpretation: {
          unknown: ""
        }
      },
      cansasMetNeed: {
        shortname: "CANSAS met needs",
        name: "CANSAS met needs",
        description: "Measures patient's met needs",
        category: "General",
        interpretation: {
          unknown: ""
        }
      },
      csi: {
        shortname: "Colorado Symptom Index",
        name: "Colorado Symptom Index",
        description: "Measures psychological and emotional difficulties felt over the past month",
        category: "General",
        interpretation: {
          subclinical: "subclinical",
          clinical: "clinical"
        }
      },
      mireccGaf: {
        shortname: "MIRECC GAF",
        name: "MIRECC Global Assessment of Functioning",
        description: "Mental Illness Research, Education, and Clinical Center (MIRECC) version of the Global Assessment of Functioning (GAF) scale. MIRECC GAF is a global instrument to assess functioning and symptom severity for patients with schizophrenia. The MIRECC GAF measures functioning on three subscales: occupational functioning, social functioning, and symptom severity. Copyright Niv, N., Cohen, A.N., Sullivan, G., & Young, A.S. (2007).",
        category: "General",
        interpretation: mireccGafThresholdNames
      },
      mireccGafOccupational: {
        shortname: "GAF - Occupational",
        name: "MIRECC Assessment of Occupational Functioning",
        description: "Measures functioning across occupational area",
        category: "General",
        interpretation: mireccGafThresholdNames
      },
      mireccGafSocial: {
        shortname: "GAF - Social",
        name: "MIRECC Assessment of Social Functioning",
        description: "Measures functioning across social areas",
        category: "General",
        interpretation: mireccGafThresholdNames
      },
      mireccGafSymptoms: {
        shortname: "GAF - Symptomatic",
        name: "MIRECC Assessment of Symptomatic Functioning",
        description: "Measures functioning across symptomatic categories",
        category: "General",
        interpretation: mireccGafThresholdNames
      },
      nsi: {
        shortname: "Neurobehavioral Symptom Inventory",
        name: "Neurobehavioral Symptom Inventory (NSI)",
        description: "Measures neurological symptoms and how they impact daily functioning",
        category: "Neurological",
        interpretation: {
          continuous: ""
        }
      },
      uminnIntake: {
        shortname: "NAVIGATE - Intake form",
        name: "NAVIGATE - Intake form",
        description: "Intake form for new enrollments in the program",
        category: "Custom"
      },
      uminnDischarge: {
        shortname: "NAVIGATE - Discharge form",
        name: "NAVIGATE - Discharge form",
        description: "Discharge form for the program",
        category: "Custom"
      },
      uminnRecruitment: {
        shortname: "NAVIGATE - Director outreach",
        name: "NAVIGATE - Director outreach",
        description: "Form logging outreach efforts by participating programs",
        category: "Custom"
      },
      uminnPPSRF: {
        shortname: "NAVIGATE - Prescriber (patient)",
        name: "NAVIGATE - Prescriber (patient)",
        description: "Patient module for completing prior to prescriber visits",
        category: "Custom"
      },
      uminnCRF: {
        shortname: "NAVIGATE - Prescriber",
        name: "NAVIGATE - Prescriber",
        description: "Prescriber module for completing during visits",
        category: "Custom"
      },
      uminnOngoing: {
        shortname: "NAVIGATE - Ongoing ",
        name: "NAVIGATE - Ongoing collection",
        description: "Navigate form filled out every 6 months",
        category: "Custom"
      },
      uminnIRTContact: {
        shortname: "NAVIGATE - IRT contact ",
        name: "IRT contact & progress note form",
        description: "Filled out after each IRT session",
        category: "Custom"
      },
      uminnIRTFidelity: {
        shortname: "NAVIGATE - IRT Fidelity ",
        name: "IRT fidelity form",
        description: "Used to rate protocol fidelity on recorded visits",
        category: "Custom"
      },
      uminnSeeMonthly: {
        shortname: "NAVIGATE - SEE monthly",
        name: "SEE monthly reporting",
        description: "Filled out each month by SEE personnel",
        category: "Custom"
      },
      uminnSeePt: {
        shortname: "NAVIGATE: SEE patient follow-up",
        name: "SEE patient monthly follow-up",
        description: "Reviewed each month for each SEE patient",
        category: "custom"
      },
      uminnSeeFidelity: {
        shortname: "NAVIGATE - SEE fidelity",
        name: "SEE fidelity assessment",
        description: "Used to rate protocol fidelity on recorded visits",
        category: "Custom"
      },
      uminnFEFidelity: {
        shortname: "NAVIGATE - FE fidelity",
        name: "Family session fidelity assessment",
        description: "Used to rate protocol fidelity on recorded family visits",
        category: "Custom"
      },
      psc17: {
        shortname: "PSC‑17",
        category: "Psychosocial",
        name: "Pediatric Symptom Checklist - 17 (PSC‑17)",
        description: "A brief screening questionnaire that is used by pediatricians and other health professionals to improve the recognition and treatment of psychosocial problems in children.",
        interpretation: {
          clinical: "investigation warranted",
          subclinical: "typical"
        }
      }
    },
    scaleBrowser: {
      removeScale: "Remove Scale",
      removeScaleAndClose: "Remove Scale & Close",
      addScale: "Add Scale",
      addScaleAndClose: "Add Scale & Close",
      noValidParticipants: "No valid participants",
      matchingParticipants: "Matching participants",
      downloadableGuide: "Downloadable Guide",
      automaticSelection: "Autoselect demographics",
      automaticSelectionDescription: "This scale has multiple different versions depending on the demographics of the patient. You may choose this scale to automatically use the most suitable variant"
    },
    assessments: {
      dashboard: {
        neverMeasured: "Not measured",
        session: "Session",
        noSession: "No Session",
        report: "Report",
        noReport: "No Report",
        noAppointments: "No upcoming appts"
      },
      navTitle: "Dashboard",
      title: "Measurements",
      description: "Pending and completed measurements are listed here",
      loadingDashboard: "Loading the dashboard...",
      nearbyAssessments: "Recent & upcoming sessions",
      legend: {
        worsening: "patient severity is worsening (magnitude of their most severe symptom is increasing)",
        improving: "patient severity is improving (magnitude of their most severe symptom is decreasing)",
        isChild: "patient is < 18 years old"
      },
      columns: {
        patient: "Patient",
        apptTime: "Scheduled",
        completedDate: "Completed",
        begunDate: "Completed",
        severity: "Severity"
      },
      pending: {
        title: "Pending Measurements",
        description: "Measurements that have not been submitted.",
        resend: "Resend",
        noAssessments: "None",
        view: "Details"
      },
      waiting: {
        title: "Upcoming",
        description: "Future measurements not open yet or still waiting on patient or provider input.",
        resend: "Resend",
        noAssessments: "None",
        view: "Invitation"
      },
      scheduled: {
        title: "Scheduled",
        description: "Measurements scheduled for the future.",
        resend: "Resend",
        noAssessments: "None",
        view: "Invitation"
      },
      myTasks: {
        title: "My tasks",
        description: "Measurements waiting on responses from me.",
        resend: "Resend",
        noAssessments: "None",
        view: "Invitation",
        note: "Note that filters do not apply to this list--it always shows the current logged-in user's tasks"
      },
      failedLogins: {
        title: "Failed logins",
        description: "Failed patient login attempts",
        none: "None",
        note: "Note that filters do not apply to this list, since failed logins cannot always be matched to a patient or provider",
        columns: {
          time: "Time",
          lastName: "Input last name",
          birthDate: "Input birthdate",
          lastSSN: "Input SSN",
          session: "Session"
        }
      },
      partial: {
        view: "In-progress Report"
      },
      completed: {
        title: "Recently Completed",
        description: "Measurements that have been completed and are ready for review.",
        responseDate: "Response Date",
        lastResponseDate: "Last Response Date",
        view: "Report",
        noAssessments: "None"
      },
      newAssessment: {
        navTitle: "New Measurements",
        step1: {
          title: "New Measurement",
          description: "Step 1: find or create patient",
          formTitle: "Search for patient",
          formDescription: "Enter the patient's email address. If the patient does not already exist, you will be asked to supply more details.",
          action: "Look Up Patient"
        },
        step2: {
          title: "Create Measurement",
          description: "Step 2: select measurement details for {patientName}"
        },
        step3: {
          title: "Measurement Created",
          description: "Step 3: the measurement has been created"
        },
        patientCreate: {
          title: "Add Patient Details",
          description: "No patient found. Please enter details for new patient"
        }
      },
      scaleChooser: {
        completedAssessments: "Some measurements are already completed or are being administered now and cannot be changed.",
        chooseScales: "Add/Remove Measure",
        noScalesSelected: "There are no scales selected",
        mismatch: "Mismatch",
        mismatchExplanation: "Warning: one or more scales you have chosen may not be indicated for this patient's demographic"
      },
      createAssessmentForm: {
        emailAddress: "Deliver mail to",
        emailOption: "Send Notification",
        missingContactDetails: "Has no registered contact details",
        notificationsDisabled: "Notifications are disabled for this patient",
        inClinicOption: "Administer in-clinic",
        emailImmediately: "immediately",
        textImmediately: "immediately",
        emailLater: "on {date}",
        textLater: "on {date}",
        emailAddressPlaceholder: "Email Address",
        allQuestions: "Require all questions (without computer adaptive skipping)",
        submit: "Create Measurement",
        assessmentContent: "Content",
        adminOptions: "Administration Options",
        recurrence: "Recurrence schedule",
        recurrencePlaceholder: 'e.g. "Every week for next 5 weeks" or "Fridays through November"',
        participantSummary: "Summary",
        careEpisode: "Episode of Care",
        careEpisodeExplanation: "(the course of treatment)",
        schedule: "Time & provider",
        withYou: "With you, {providerName}",
        withOtherProvider: "With ",
        customizeMessage: "Customize Message",
        useDefaultMessage: "Use Default Message",
        emailMessagePlaceholder: "Enter a custom message to send via email. The email will automatically include a greeting and a link to the assessment.",
        textMessagePlaceholder: "Enter a custom message to send via text. It can be a maximum of 110 characters. The text automatically includes a link to the assessment.",
        interviewTitle: "Interview Format",
        interviewMessage: "Will be administered in interview format by the provider. Notifications will be disabled, and these measures will not form part of any self-report assessments completed by the respondent",
        autoplan: "Automatically Plan Scales",
        autoplanSubtitle: "The system will work out what to administer",
        customScales: "Custom Scales",
        customScalesSubtitle: "Choose exactly what scales to administer",
        autoplanSummary: "The exact scales administered are governed by the following protocols and could change:",
        showExampleSchedule: "Show Example Measurement Schedule",
        editMeasurementPlans: "Edit Plans",
        manualScalesAdministered: "The following non-plan scales will be administered:",
        addAdditionalScale: "Add Additional scale",
        additionalScaleWarning: "These additional scales will be administered once only, and will not be automatically moved to a new measurement if this measurement is canceled. If you want to use this scale repeatedly, create a new measurement plan",
        opensAt: "Opens at {date}.",
        openNow: "Open now.",
        closesAt: "Closes at {date}.",
        expired: "Measurement window has expired.",
        canceled: "This measurement has been canceled.",
        partial: "This measurement was partially completed but is now closed."
      },
      invitationDrawer: {
        title: "Invitation Details",
        invitationDetails: "Details",
        viewReport: "Report"
      },
      invitationDetails: {
        title: "Invitation Details",
        noResponseYet: "No response yet",
        externalUrlText: "The measurement can be taken at",
        backToDashboard: "Back to Measurement Dashboard",
        status: "Status",
        dateCompleted: "Date Completed",
        sendEmail: "Send email now",
        sendNotifications: "Send notifications now",
        sendText: "Send Text Message now",
        sendEmailDetail: "Send a link to the patient for the measurement to be completed online",
        resendNotifications: "Resend notifications now",
        resendNotificationsDetail: "Resend the link to the patient for the measurement to be completed online",
        takeAssessment: "Take measurement",
        takeAssessmentAsProvider: "Enter data in bulk",
        beginInterview: "Begin Interview",
        continueInterview: "Continue Interview",
        measures: "Measures",
        takeAssessmentDetail: "Go to the measurement to take it on this computer now.",
        updateDate: "Change effective date",
        updateDateFooter: "This means that the application will treat those responses as being entered on that date for timeline and reporting purposes.<br>\n                        <span class=\"detail\">(audit logs will still be preserved showing the actual input dates)</span>",
        updateDateText: "Enter revised effective date for {name}'s responses.",
        lockedOut: "Locked Out",
        lockedOutDescription: "Too many incorrect login attempts have caused this invitation to be locked. In order to continue, you must reset the invitation.",
        reset: "Reset and allow login",
        deleteSingle: "Delete only this measurement",
        deleteSeries: "Delete all future measurements in this series",
        noActions: "This measurement cannot be changed.",
        expiresAt: "This measurement will expire on {date}",
        noExpiry: "This measurement will not expire and you can enter data at any time",
        expired: "This measurement has completed and cannot be altered",
        reopenInvitation: "Reopen this invitation for 24 hours",
        scheduled: "Scheduled",
        sent: "Sent",
        notificationError: "Will not send",
        notificationStatusDetails: {
          missing_details: "No contact details present for this participant",
          forbidden: "This participant has opted out of communications",
          not_needed: "This notification will not be sent as it is no longer required"
        },
        planningDetails: "Automatic Planning Details",
        planningExplanation: "This table shows all the measures that have been considered for inclusion in this session, and if they have not been selected, the reason for non-selection. This may change for future sessions as sessions prior to it are canceled or fulfilled.",
        showPlanningDetails: "Show Details",
        hidePlanningDetails: "Hide Details",
        planningScale: "Scale",
        planningAdminister: "Administer?",
        planningSetby: "Set by",
        planningStatus: "Status",
        planningReason: "Details"
      },
      report: {
        title: "Measurement Report",
        "patientInfo.title": "Patient Information",
        "risks.title": "Risks",
        symptomsOverview: {
          title: "Symptoms Overview",
          legend: {
            dots: "past visits",
            arrow: "significant change",
            intake: "intake report",
            recent: "recent report",
            bar: "current value",
            comparison: "comparison value"
          }
        },
        "skillsOverview.title": "Skills Overview",
        "talkTopics.title": "Talk Topics",
        "therapyResponse.title": "Therapy Response",
        "detailList.title": "Full Detail",
        fullDetail: "Full Report Detail",
        noDetail: "No detail available",
        noDataSelected: "You currently have no data selected. Select a broader time range to see more details",
        completedBy: "completed by",
        partialWarning: "This report contains measurements which were not fully completed by participants, interpret with care",
        incompleteWarning: "This report has not been completed by at least one of the expected respondents",
        wrongPopulationWarning: "The population norm selected does not have data for this measure",
        wrongPopulationExplanation: "Choose another norm to see data for this measure",
        takingDetail: "Taken in",
        beginInterview: "Begin interview with {name}",
        incompleteInterviews: "There are interview questionnaires to be completed:",
        incompleteAfterSession: "There are post-session questionnaires to complete",
        beginAfterSessionInvitation: "Begin Questionnaire for {name}",
        actions: {
          targetSymptom: "Target",
          untargetSymptom: "Remove Target"
        },
        symptomBox: {
          noData: "none"
        },
        percentile: "%ile",
        risk: {
          body: {
            title: "Self-harm Risk",
            minimalRisk: "Minimal risk",
            riskUnknown: "Not evaluated",
            atRisk: "Self-harm Risk",
            riskSymptom: "Elevated Self-Harm Risk",
            createPlan: "Create safety plan"
          },
          therapy: {
            title: "Therapy Risk",
            minimalRisk: "Minimal risk",
            atRisk: "Therapy Risk",
            riskSymptom: "Therapy expectation",
            allianceNote: "When therapy has not started, initial alliance estimate may be based on the patient's belief that things will improve"
          },
          medications: {
            title: "Medications",
            minimalRisk: "Reports ok",
            atRisk: "Medications",
            riskSymptom: "Has concerns"
          },
          substanceUse: {
            title: "Substance Use",
            minimalRisk: "Minimal Risk",
            atRisk: "Substance Use Risk",
            riskSymptom: "Use reported"
          },
          therapyPrefs: {
            title: "Preferences",
            atRisk: "Preferences"
          }
        },
        viewDetail: "view info",
        therapyPrefs: {
          title: "Therapy Prefs",
          noChanges: "No changes"
        },
        recentChanges: {
          title: "Since Prior Visit"
        },
        goals: {
          title: "Goals or Problems",
          noActive: "No active goals/problems",
          createNew: "Create New Goal/Problem",
          startGoalText: "Start a goal for {name}",
          noData: "No data yet",
          notInTreatment: "This patient is not in active treatment. Begin an episode of care to start goals planning.",
          goal_attainment_scaling: {
            title: "Goal Attainment Scaling",
            description: "Rated on a scale of -2 to 2, where -2 is 'Below Expected' and 2 is 'Above Expected'. Goals should be phrased as a positive thing to attain."
          },
          top_problem: {
            title: "Top Problems",
            description: "Rated on a scale of 0 to 4, where 0 is 'not a problem at all' and 4 is 'a very big problem'. Goals should be phrased as a problem the patient is experiencing."
          },
          goal_likert: {
            title: "7 Point Likert",
            description: "A simple 7 point likert, with 1 = not at all, 4 = somewhat and 7 = completely."
          }
        },
        peabodyMeasure: {
          title: "Peabody Measure"
        },
        dsm: {
          dsm5Level1Adult: "DSM-5 Cross-Cutting Adult",
          dsm5Level1Adolescent: "DSM-5 Cross-Cutting Adolescent",
          dsm5Level1Child: "DSM-5 Cross-Cutting Child"
        },
        sdq: {
          title: "SDQ"
        },
        symptomTrends: {
          title: "Trends",
          firstTime: "This is the patient's first report",
          worsened: "Significantly Worsened",
          improved: "Significantly Improved",
          targeted: "Targeted"
        },
        symptomExplorer: {
          title: "Symptoms and Strengths",
          comparison: "Compare to",
          trends: "Trends",
          strengths: "Strengths",
          overview: "Tracker",
          selectTitle: "Currently showing"
        },
        symptomDetails: {
          title: "Symptom Details",
          noneSelected: "Select a symptom",
          explanation: "Click any graph point to show detail, including the individual patient responses.",
          explanationTable: "Click any row to show detail, including the individual patient responses.",
          dimmedExplanation: "Dimmed bars indicate the patient was below the threshold for full scale adminstration",
          thresholdNotMet: "patient was below screening threshold for full administration of this scale",
          responses: "Patient Responses",
          date: "Date",
          noResponses: "There are no responses"
        },
        patientTimeline: {
          title: "Patient Timeline",
          firstTime: "This is the patient's first report"
        },
        preliminary: "This is a preliminary report. This page will reload the final report automatically when it becomes available.",
        reportNumber: "Report",
        demographics: {
          title: "Patient Demographics",
          age: "Age",
          gender: "Sex",
          genderIdentity: "Gender",
          housing: "Housing",
          relationship: "Relationship",
          ethnicity: "Ethnicity",
          pronouns: "Pronouns"
        },
        holisticResponses: {
          title: "Open-ended Responses",
          goalsForCounseling: "Goals for counseling:",
          currentProblemStrategy: "Current strategy for problems:",
          otherTopics: "Anything else?",
          notAnswered: "Not answered"
        },
        therapies: {
          title: "t<sub>x</sub>"
        },
        conditions: {
          title: "d<sub>x</sub>"
        },
        settings: {
          title: "Settings",
          norm: {
            description: "The population that scores are computed in relation to",
            title: "Reference Norm",
            outpatient: "Outpatient",
            inpatient: "Inpatient",
            general: "Gen. Population",
            intreatment: "In Treatment"
          },
          reportDate: {
            title: "Report Selector",
            description: "Which report to use as the latest report"
          },
          compareReportTo: {
            title: "Compare To",
            description: "Which report to compare the latest report against",
            intake: "Intake Report",
            previous: "Previous Report"
          },
          editResponse: "Edit Response",
          otherSettings: "Other Settings"
        },
        partial: {
          warning: "This is a partial report, scores shown are incomplete and should NOT be relied on for analysis.",
          title: "Partial Report"
        },
        standardMeasures: {
          title: "Supplemental Measures",
          noData: "No supplemental measures to display",
          norm: "Comparison norm",
          expandForSubscores: "expand for subscores"
        },
        customMeasures: {
          title: "Custom Measures",
          noData: "No custom measures to display",
          explanation: "Click a date to show individual responses"
        },
        sdohMeasures: {
          title: "Social Determinants Of Health"
        },
        symptom: "symptom",
        skill: "skill",
        scoreExplanation: "Score (standard deviations from mean)",
        viewDetailText: "Select a {name} to view detail."
      },
      invitations: {
        exit: "You have exited the measurement",
        timeout: "The measurement has timed out",
        complete: "You have completed the measurement. The results may take several minutes to populate in the report. You are now being redirected back to your previous page.",
        goBackToAssessment: "Go back to measurement",
        returnToPrevious: "Return to previous page",
        wrongProvider1: "This measure is intended for completion by {name}.",
        wrongProvider2: "By hitting continue, you assert that you are with them and are completing it on their behalf.",
        continueToAssessment: "Continue",
        alreadyComplete: "This measurement has already been completed",
        editResponse: "Edit / {fullName}"
      }
    },
    patients: {
      createPatient: {
        title: "Create a new patient",
        description: "Enter information to create a new patient"
      },
      create: {
        crumb: "Create Patient"
      },
      editForm: {
        submit: "Submit",
        primaryClinician: "Primary Clinician",
        changeToTestWarning: "You are changing this patient to be a test patient. They will no longer appear on your live dashboard and will not be counted for analytics",
        changeToLiveWarning: "You are changing this patient to be a real patient. They will appear on your live dashboard and will be counted for analytics",
        confirmTestChange: "Confirm Change"
      },
      list: {
        navTitle: "Patients",
        title: "Patient List",
        description: "Find and view patients",
        showPatient: "Detail",
        latestAssessment: "Report",
        createAssessment: "Create Measurement",
        scheduleRecurringAssessments: "Schedule",
        createPatient: "Create New Patient",
        scheduleSession: "Schedule Session",
        measureNow: "Schedule measurement",
        measureNowShort: "Schedule",
        planMeasurement: "Measurement Protocol",
        planMeasurementShort: "Plan",
        delete: "Delete",
        confirmDelete: "Are you sure you want to delete this patient? This cannot be undone and should not be used on real-life data",
        careEpisodeFieldList: "View Fields",
        enableNotifications: "Enable Notifications",
        disableNotifications: "Disable Notifications",
        notificationsAreDisabled: "Notifications are currently disabled for this patient. Neither they nor related people will receive any communications from Mirah. They are still able to take assessments via kiosk.",
        notificationPreferenceNone: "This patient has a notification preference of 'None'. They will not recieve text or email communications from Mirah. They are still able to take assessments via kiosk. This can be changed in the 'Edit Demographics' menu.",
        noContactInformation: "There is no contact information for this patient. They will not recieve notifications.",
        notificationPreferenceMissingInformation: "This patient has a notification preference of '{preference}', but there is no contact information. They will not recieve notifications.",
        integrationPrimaryProviderOnly: "This patient has multiple care team members, but only appointments for {provider} will be measured.",
        integrationPrimaryProviderOnlyEdit: "Edit Primary Clinician"
      },
      show: {
        title: "Patient Details",
        description: "View details for a patient",
        editDetails: "Edit Demographics",
        resetPassword: "Reset Password",
        "measure-now": {
          crumb: "Create measurement"
        },
        edit: {
          crumb: "Edit"
        },
        sessionsPast: "Past Sessions",
        sessionsUpcoming: "Upcoming Sessions",
        availableMeasures: "Available measures",
        measureDragInstructions: "Drag to add to a session",
        coverages: {
          create: {
            crumb: "Add new insurance details"
          }
        }
      },
      edit: {
        title: "Edit Patient",
        description: "Edit details for a patient"
      },
      info: {
        title: "Demographics"
      },
      resetPassword: {
        description: "Reset password for kiosk use"
      },
      claim: "Claim",
      showTestData: "Test patients",
      carePlanList: "Measurement Plans",
      sessionList: "Measurements",
      consentList: "Consent Form Acceptance History",
      conditionList: "Diagnoses",
      careEpisodeList: "Episodes of Care",
      createCareEpisode: "Begin new episode of care",
      careEpisodeFields: "Static Fields",
      careEpisodeFieldList: {
        crumb: "Fields",
        value: "Value",
        name: "Name",
        saveChanges: "Save Changes",
        undoAll: "Undo All Changes",
        unsavedChanges: "You have unsaved changes",
        noChanges: "No changes made"
      },
      coverageList: "Insurance Eligibility",
      createCoverage: "Add new insurance details",
      experimentsList: "Enrolled Experiments"
    },
    carePlans: {
      editCarePlan: "Edit Measurement Plan",
      defaultName: "{carePlanName}: {startDateString} - {endDateString}",
      list: {
        create: "Create Measurement Plan"
      },
      create: {
        title: "Create a Measurement Plan",
        description: "for {patientName}"
      },
      createForm: {
        useExisting: "Choose an existing measurement plan",
        createCustom: "Create a custom measurement plan",
        details: "Details",
        nameDescription: "This name will be used to refer to the measurement plan across the platform",
        selectCarePlan: "Select a measurement battery",
        dates: "Scheduling",
        participants: "Participants",
        submit: "Save",
        showAdvancedOptions: "Show advanced options",
        hideAdvancedOptions: "Hide advanced options",
        leaveEndBlank: "Leave blank to continue indefinitely",
        existing: "This patient already has an active measurement plan for this battery",
        existingLink: "Go to it"
      },
      editForm: {
        details: "Details",
        dates: "Dates",
        leaveEndBlank: "Leave blank to continue indefinitely",
        submit: "Save",
        participants: "Participants",
        scaleSchedule: "Edit Scale Schedule"
      },
      scaleEditor: {
        showAdvanced: "Show Advanced",
        showBasic: "Show Basic",
        selectScale: "Select a scale"
      },
      chooser: {
        noneAvailable: "There are no active measurement plans",
        addNew: "Create a new measurement plan"
      },
      participants: "Participants:",
      noParticipants: "No participants",
      show: {
        title: "Measurement Plan Details",
        description: "for {patientName}"
      },
      edit: {
        title: "Edit Measurement Plan",
        description: "for {patientName}"
      },
      scheduleSummary: {
        measureOnsetFromCareEpisodePeriodStart: "care episode start",
        measureOnsetFromNextCalendarQuarter: "start of quarter after care episode start",
        initialBoth: "starting at the later of session {onsetSession} and {onsetTime} after {measureOnset}",
        initialTime: "starting {onsetTime} after {measureOnset}",
        initialSession: "starting at session {onsetSession}",
        onceOnly: "once only",
        repeatBoth: "repeating the longer of every {repeatSessions} sessions or every {maxRepeatInterval}",
        repeatSession: "repeating every {repeatSessions} sessions",
        everySession: "Every session",
        repeatTime: "repeating every {maxRepeatInterval}",
        endingBoth: "ending at the later of session {discontinueSession} and {discontinueTime} after care episode start",
        endingTime: "ending {discontinueTime} after {measureOnset}",
        endingSession: "ending at session {discontinueSession}",
        oncePerPeriod: 'measured once per {period}',
        rollout: 'rolling out over {rolloutPeriod} for patients in care before {rolloutBefore}',
        periods: {
          calendar_quarter: "calendar quarter",
          calendar_year: "calendar year",
          calendar_month: "calendar month"
        }
      },
      participantList: "Participants: {participantList}",
      norse: {
        name: "CAMS",
        shortname: "CAMS",
        description: "a multi-dimensional adult measure designed for continuous measurement of symptoms"
      },
      ptpb: {
        name: "Peabody Treatment Progress Battery",
        shortname: "PTPB",
        description: "The Peabody Treatment Progress Battery (PTPB) is an integrated comprehensive set of 11 assessments measuring outcomes (e.g. symptom severity, life satisfaction) and treatment processes (e.g. therapeutic alliance, counseling impact). The PTPB uses a common factors approach to the measurement of treatment, which allows for the battery to be used with most treatment modalities. Assessments are completed by patients and caregivers. *The PTPB is intended for youth aged 11 to 18 in outpatient treatment settings. However, clinical judgment may indicate use among patients younger than 11 years of age if they have at least a 4th grade level of reading comprehension. Similarly, clinicians may choose to use the measures with patients who reach 18 years of age or beyond in continuing treatment."
      }
    },
    careEpisodes: {
      list: {
        create: "Begin a new episode of care"
      },
      create: {
        title: "Begin a new episode of care",
        description: "for {patientName}"
      },
      edit: {
        title: "Edit an episode of care",
        description: "for {patientName}"
      },
      show: {
        title: "Episode of Care Details",
        description: "for {patientName}",
        addCarePlan: "Add measurement plan"
      },
      editForm: {
        dates: "Dates <span class='detail'>The time period of this care episode</span>",
        organization: "Unit <span class='detail'>The unit responsible for this care episode</span>",
        careTeam: "Care Team",
        selectOrganizationForCareTeam: "Select a unit to edit the care team",
        existing: "There is an active episode of care for this unit.",
        existingLink: "Go to it."
      },
      calculatedName: "{organizationName}, {startDateString} - {endDateString} ({status})",
      calculatedNameWithTreatmentService: "{treatmentServiceName}, {startDateString} - {endDateString} ({status})",
      editCareEpisode: "Edit",
      discharge: "Discharge",
      changeEpisode: "change",
      confirmDischarge: "Are you sure you want to discharge this episode of care?",
      selector: {
        for: "Unit"
      }
    },
    careEpisodeProviders: {
      selectProvider: "Choose Provider",
      selectRelationship: "Choose Role",
      showAllProviders: "showing&nbsp;from&nbsp;this&nbsp;unit.",
      showProvidersInMyOrg: "showing&nbsp;all&nbsp;providers"
    },
    patientSessions: {
      selector: {
        addToExisting: "Add to {targetDate} session with {providerName}",
        addToEpisode: "Start a new session in {episodeName}",
        newEpisode: "Begin a new episode of care with a new session"
      },
      createSession: {
        title: "New measurement",
        description: "Schedule a measurement for {patientName}",
        submit: "Confirm Session",
        submitNoPlan: "Confirm Session",
        submitAndPlan: "Confirm Session & View Measurement Protocol",
        emailSentImmediately: "Email will be delivered to patient immediately",
        emailSentPriorToAppt: "Email will be delivered to the patient 24 hours prior to the appiontment time"
      },
      showSession: {
        description: "Measurement Details",
        title: "{patientName} - {date}",
        deleted: "This measurement has been deleted",
        deleteFailed: "This measurement cannot be deleted.",
        confirmDelete: "Are you sure you want to permanently delete this measurement?",
        confirmCancel: "Are you sure you want to cancel this measurement?  It will no longer appear in dashboards and no invitations will be sent",
        canceled: "This measurement has been canceled",
        cancelFailed: "This measurement cannot be canceled."
      },
      editSession: {
        title: "Edit Measurement Details",
        description: "Measurement details for {patientName}"
      },
      drawer: {
        title: "Measurement Details",
        showDetails: "Show details"
      },
      scheduleRecurring: {
        updateSeriesChoice: "You are changing a session in a series of recurring sessions. <br> Do you want your changes to impact just this session, <br> or all sessions in the series that have not yet occurred or been responded to?",
        updateSeries: "Update series",
        updateSingle: "Update single session",
        title: "Plan Measurement",
        description: "for {patientName}",
        firstSessionDate: "First session to include measures",
        submit: "Schedule Sessions",
        recurrence: "Recurrence",
        endDate: "End Date",
        numberOfSessions: "Number of sessions",
        intervalDays: "Days between sessions",
        endCriteria: "If both an end date and number of sessions are entered, sessions will be created up to whichever happens first.",
        carePlans: "Measurement Content",
        careEpisode: "Care Episode",
        scheduling: "Schedule"
      },
      change: "change",
      filtered: "With a different provider.",
      alterScales: {
        addingScaleTitle: "Adding scales to {session}",
        autoplanExplanationAdd: "You can add to this individual session or create a new plan. If you add to the session and the session is canceled, the scales will not be administered. If you create a plan, the scales will be added to subsequent sessions until they are completed.",
        manualExplanationAdd: "Scales will be added with default participants and settings.",
        addToSession: "Add to session",
        createPlan: "Create plan",
        removingScaleTitle: "Removing scales from {session}",
        removeFromSession: "Remove from session",
        removeFromPlan: "Remove from plan",
        autoplanExplanationRemove: "These scales form part of an automated schedule. Removing them here will stop them being administered in this session but they will continue to be administered in the future. To stop the scale being administered at all, visit Measurement Protocol.",
        manualExplanationRemove: "Scales will be removed from all relevant respondents. If a respondent has no more scales left their invitation will be canceled."
      },
      saving: "Measurements are being created. This may take several seconds if you are creating many upcoming sessions.",
      deleting: "Measurements are being deleted. This may take several seconds if you are deleting a large series of measurements.",
      configuration: {
        notifications: "Notifications",
        notificationTimingBefore: "For 'before' scales:",
        notificationTimingBeforeItem: "{at} before",
        notificationTimingDuring: "At session date for 'during' scales",
        notificationTimingAfter: "For 'after' scales:",
        notificationTimingAfterItem: "{at} after",
        notSent: "Not sent",
        feedback: "Completion response window",
        expiry: "Expiry",
        openBefore: "{before} before to {after} after session for 'before' scales",
        openDuring: "{before} before to {after} after session for 'during' scales",
        openAfter: "{before} after to {after} after session for 'after' scales",
        expiresAt: "Session closes {at} after appointment"
      }
    },
    scheduling: {
      appointments: {
        appointmentDetails: "Scheduling",
        scheduled: "Scheduled"
      }
    },
    relatedPeople: {
      create: {
        title: "Create a related person",
        description: "Enter information for a relative of {patientName}",
        username: "A username will be automatically generated on the basis of the given details."
      },
      edit: {
        title: "Edit a related person",
        description: "Update information for a relative of {patientName}"
      },
      list: {
        edit: "Edit",
        editLink: "Edit related people",
        create: "Add a related person"
      }
    },
    logout: {
      navTitle: "Logout"
    },
    exitToKiosk: {
      navTitle: "Kiosk mode",
      title: "Enter Kiosk Mode",
      exitWarning: "By entering kiosk mode you will be logged out.",
      exitContinue: "Do you wish to continue?",
      continueToKiosk: "Continue to Kiosk",
      goBack: "Go back"
    },
    exitToPatient: {
      exitWarning: "In order to take this measurement, you will be logged out of provider mode.",
      exitContinue: "Do you wish to continue?",
      continueToKiosk: "Continue to measurement",
      goBack: "Go back"
    },
    forgottenPassword: {
      title: "Forgot Password",
      emailSent: "If an account exists at the email address given, an email has been sent.",
      description: "If you have forgotten your password, you may request a password reset. An email will be sent to your account containing password reset instructions.",
      sendResetEmail: "Send Reset Email",
      backToLogin: "Back to login page"
    },
    passwordReset: {
      title: "Reset Your Password",
      description: "Complete this form to change your password",
      restrictions: "Passwords must be a minimum of 8 characters and contain at least one upper case letter and at least one number",
      newPassword: "New Password",
      newPasswordConfirmation: "Confirm New Password",
      submit: "Reset Password",
      errorTitle: "Invalid Token",
      errorDescription: "The link you used is invalid or has expired. In order to reset your password, you will need to generate a new one.",
      errorLink: "Reset my password"
    },
    confirmUser: {
      title: "{firstName}, welcome to Mirah!",
      description: "Please select a password to continue",
      submit: "Set my password",
      usernameDescription: "Your username to log in to the system will be:"
    },
    confirmUserExpired: {
      title: "This page has expired",
      description: "Your account has already been created, login below"
    },
    controls: {
      showAllPatients: "all Providers",
      showMyPatients: "me",
      title: "Filters",
      provider: "Provider",
      organization: "Unit",
      flags: "Flags",
      therapies: "Therapies",
      conditions: "Conditions",
      showAllOrganizations: "all",
      showMyOrganizations: "mine"
    },
    noHipaa: {
      reminder: "Reminder: this is a demo site - do NOT use for real patients"
    },

    editProfile: {
      title: "Edit Your Profile",
      description: "Edit your user profile",
      firstName: "First Name",
      lastName: "Last Name",
      submit: "Save Profile",
      username: "Username (to be used for login)",
      email: "Email",
      phoneNumber: "Phone Number",
      notifications: {
        edit: "Edit Notification Preferences",
        explanation: "Receive emails for events and summary analytics",
        title: "Notifications for {name}"
      },
      notificationSelect: "Assessment notifications"
    },

    synopsis: {
      heading: "Full Detail",
      generateNote: "Generate note",
      showFullDetail: "Show",
      copyText: "Copy text",
      showFullQuestion: "Show full question text",
      showAbbreviatedQuestion: "Show abbreviated question text",
      groupBySeverity: "Endorsed scales first",
      scalesAlpabetically: "List scales alphabetically",
      overview: "Overview",
      format: {
        toggleTitle: "Format",
        html: "Html",
        text: "Text"
      },
      patient: {
        reportComplete: "{name} completed the Mirah self-measurement on {effectiveDate}.",
        recentEvents: "Since the prior visit, the patient reported"
      },
      openEnded: {
        counselingGoals: {
          answer: "They report current goals for counseling as: {answer}",
          noAnswer: ""
        },
        currentProblemStrategy: {
          answer: "They report a current problem strategy of: {answer}.",
          noAnswer: ""
        },
        otherTopics: {
          answer: "In the measurement, they also mentioned: {answer}.",
          noAnswer: ""
        }
      },
      typicalSymptoms: {
        text: "Results in typical range include:",
        html: "Typical results"
      },
      atypicalSymptoms: {
        text: "Atypical results include:",
        html: "Atypical results"
      },
      unclassifiedSymptoms: {
        text: "Other results include:",
        html: "Other results"
      },
      scaleDetail: {
        abnormalTitle: "ATYPICAL RESULTS",
        normalTitle: "TYPICAL RESULTS",
        unclassifiedTitle: "OTHER RESULTS",
        severeEndorsements: "Severe endorsements",
        moderateEndorsements: "Moderate endorsements"
      },
      normedScaleSummary: {
        abnormal: "in the {percentileWithSuffix} percentile of {mnemonic} population.",
        normal: "in the {percentileWithSuffix} percentile of {mnemonic} population.",
        reversed: "is {interpretation} in the {percentileWithSuffix} percentile of {mnemonic} population"
      },
      scaleSummary: {
        abnormal: "with a score of {value}.",
        normal: "with a score of {value}.",
        reversed: 'is {value}, falling in the range of "{interpretation}"'
      },
      answers: {
        abnormal: {
          none: "all answered items in typical ranges."
        }
      },
      subscales: "The scale contains the following subscales",
      showScaleDescriptions: "Show scale descriptions"
    },
    clientPasswordReset: {
      title: "You are about to reset the password for {name}",
      warning: "You are about to reset the password for {name}",
      explanation: "Once this is complete, you will be given a new temporary password to give them. When they log in, they will be asked to reset their password immediately.",
      action: "Reset Password",
      titleComplete: "Password reset for {name}",
      explanationComplete: "They may log in using their username <strong>{username}</strong> and the password below",
      closeWarning: "Once you press finish below, the password will no longer be shown. Please write down the password to give to the patient now.",
      actionComplete: "Finish"
    },
    updatePassword: {
      title: "Please Change Your Password",
      description: "Your password has expired. Please select a new password to use",
      newPassword: "New Password",
      newPasswordConfirmation: "Confirm new password",
      submit: "Change Password",
      skip: "Skip for now"
    },
    loginCodes: {
      explanation: "If you are having problems signing a patient in using their details, you can instead use the code below to bypass this process. Select 'keycode login' on the login screen on the patient's tablet or phone and input the following.",
      expiry: "This code expires in {duration}",
      generateLoginCodes: "Instant Login",
      close: "Close"
    },
    stats: {
      navTitle: "Stats"
    },
    reports: {
      crumb: "Analytics",
      navTitle: "Analytics"
    },
    admin: {
      users: {
        createUser: "Create New Staff User"
      }
    },
    measureNow: {
      title: "Create measurement",
      description: "Take an immediate measurement for {patientName}",
      selectSession: "Session",
      submit: "Confirm Measurement",
      selectASession: "Please select a session first"
    },
    surveyEdit: {
      save: "Save",
      return: "Return",
      saveAndViewReport: "Save & View Report",
      saveAndReturn: "Save & Return",
      viewReport: "View Report",
      undoAll: "Undo All",
      unsavedChanges: "You have unsaved changes",
      noChanges: "No changes made",
      noQuestions: "There are no questions to display",
      unsavedChangesWarning: "You have unsaved changes. If you navigate away now, these changes will be lost.",
      continueEditing: "Continue Editing",
      leavePage: "Leave Page (Changes will be lost)",
      saving: "Saving answers. This may take several seconds."
    },
    demoUserCreate: {
      title: "Create a demo user",
      name: "Name",
      email: "Email Address",
      phoneNumber: "Phone Number",
      scaleSet: "Scale Set"
    },
    subscriptions: {
      weekly_adherence_email: {
        title: "Weekly Adherence Email",
        description: "Receive a weekly email containing information about all patients' adherence to measurement."
      },
      assessment_complete: {
        title: "Assessment Complete Alerts",
        description: "Receive an email when a patient you are on the care team for, or someone related to them, completes an assessment"
      }
    },
    patientFlags: {
      activeFlags: "Active Flags",
      inactiveFlags: "Inactive Flags",
      noActive: "There are no active flags for this patient",
      noUnset: "There are no unset flags for this patient",
      setByAlgorithm: "Set automatically",
      confirmClear: "Confirm Clear",
      clearWarning: "Once cleared, this flag will be set again if new data triggers the algorithm",
      confirmSet: "Confirm Set"
    },
    privacyMode: {
      noDashboard: "Your dashboard is not shown because Discreet Mode is on.",
      noSmartSearch: "Smart search is disabled because Discreet Mode is on."
    },
    smartSearch: {
      nextAppointment: "Next appointment {appointmentDate}",
      lastAppointment: "Last appointment {appointmentDate}",
      noAppointments: "No appointments",
      searchPlaceholder: "Type a patient name...",
      loadingMessage: "Loading patients..."
    },
    entityPaths: {
      allProviders: "All Providers",
      searchProviders: "Search Providers",
      allPatients: "All Patients",
      allCareEpisodes: "All Episodes Of Care",
      searchPatients: "Search Patients",
      allOrganizations: "All Units",
      allTreatmentTracks: "All Treatment Tracks",
      searchOrganizations: "Search Units",
      allTreatmentServices: "All Treatment Services",
      searchTreatmentServices: "Search Treatment Services",
      allPanels: "All Panels",
      searchPanels: "Search Panels",
      experiment: {
        label: "Experiment",
        search: "Search Experiments",
        select: "Select Experiment"
      },
      "experiment-variant": {
        label: "Experiment Group",
        search: "Search Experiment Groups",
        select: "Select Experiment Group"
      },
      condition: {
        label: "Condition",
        search: "Search Conditions",
        select: "Select Condition"
      },
      "min-age": {
        label: "Min age"
      },
      "max-age": {
        label: "Max age"
      },
      gender: {
        label: "Gender"
      },
      "care-unit-type": {
        label: "Care Unit",
        search: "Search Care Units",
        select: "Select Care Unit"
      },
      "treatment-track": {
        label: "Treatment Track",
        search: "Search Treatment Track",
        select: "Select Treatment Track"
      },
      "institute-condition": {
        label: "Diagnosis",
        search: "Search Diagnoses",
        select: "Select Diagnosis"
      },
      "treatment-service": {
        label: "Treatment Service",
        search: "Search Treatment Service",
        select: "Select Treatment Service"
      },
      "entity-decision-algorithm": {
        label: "Decision Support Algorithm",
        search: "Search Decision Support Algorithms",
        select: "Select Decision Support Algorithm"
      },
      "workflow": {
        label: "Decision Support Workflow",
        search: "Search Decision Support Workflows",
        select: "Select Decision Support Workflow"
      },
      "workflow-action": {
        label: "Decision Support Action",
        search: "Search Decision Support Actions",
        select: "Select Decision Support Action"
      },
      "panel": {
        label: "Panel",
        search: "Search Panels",
        select: "Select Panel"
      }
    },
    measurementPlans: {
      navTitle: "Measurement Plans",
      chooseFilters: "Showing plans for...",
      activeExplanation: "* All supplemental and compliance measurement plans will be administered. The first core measurement plan to meet the demographics requirements will be administered.",
      createEntityMeasurementPlanCore: "Replace core plan",
      createEntityMeasurementPlanOther: "Add a compliance or supplemental plan",
      managePatientMeasurements: "Patient Measurement Protocol",
      supersedingPlansWarning: "There are {count} measurement plans at a lower level that might affect measurement.",
      supersedingPlansExplanation: "Supplemental and compliance measurement plans at a more detailed level will be administered in addition to measurement plans above. More detailed core measurement plans may be administered instead of the core measurement plan above.",
      viewDetails: "Show details",
      hideDetails: "Hide details",
      supersedingPlans: "More Specific Measurement Plans",
      resetAllFilters: "Reset All",
      showingPlansFor: "Showing active measurement plans for...",
      patientPlans: "Patient-specific measurement plans",
      patientPlansWarning: "There may be patient-specific measurement plans that will overwrite these plans.",
      noScaleSelected: "No scale selected",
      addAScale: "Add a scale",
      addAlternatingScales: "Add a set of alternating scales",
      addNewAlternatingScale: "Add a scale",
      planCreated: "Measurement plan created",
      planUpdated: "Measurement plan updated",
      editPlan: "Edit this measurement plan",
      calculateSchedule: "Show Example Measurement Schedule",
      scheduleCalculatorExplanation: "Enter a set of dates to show an example of what measures would be administered for those dates. This does not create any sessions",
      scheduleDemographicsRequired: "Enter more demographic details to see exactly what measures would be administered.",
      noResults: "No results",
      saveChanges: "Save Changes",
      saveChangesAndReplan: "Save Changes & Replan Sessions",
      replanningWarning: "There are {affectedSessionCount} existing sessions that may be affected by this change. Replanning will happen in the background and may take several minutes.",
      saveExplanation: "Because this is a {planType} measurement plan, it will always be administered to anyone with the criteria listed. When you save this plan, all sessions that are affected will be replanned to include this plan.",
      saveExplanationCore: "Each session can only have one core protocol. When you save this plan, the system will recalculate which sessions this plan will affect, and replan those sessions. Sessions that already have a measurement plan at a lower level (e.g. that additionally specify a patient or provider) will not be changed.",
      disablePlanHeaderText: "You are discontinuing {planName} for",
      alterPlanExplanation: {
        disable: {
          core: "This will discontinue this protocol at this and lower levels. Future sessions that are currently scheduled to use this protocol will be re-planned to take the next highest priority.",
          compliance: "This will discontinue this protocol at this and lower levels. Future sessions involving this measurement plan will be re-planned. If scales used in this protocol are administered by other active protocols, they might not be removed.",
          supplemental: "This will discontinue this protocol at this and lower levels. Future sessions involving this protocol will be re-planned. If scales used in this plan are administered by other active protocols, they might not be removed."
        },
        enable: {
          core: "This will enable this protocol at this and lower levels. This takes precedence over higher level protocols and may change the core protocol of existing sessions.",
          compliance: "This will enable this protocol at this and lower levels.",
          supplemental: "This will enable this protocol at this and lower levels."
        }
      },
      disabledPlanExplanation: "This plan represents the disabling or exclusion of a plan at a higher level.",
      changePathToEdit: "To edit this plan, view settings for:",
      newMeasurementPlan: "New Blank Protocol",
      newMeasurementPlanFromTemplate: "New Protocol From Template",
      useExistingMeasurementPlan: "Use Pre-existing Protocol",
      modifyMeasurementPlanWarning: "Changing this protocol will also modify all other measurement plans using this schedule.",
      alterPlanChangedSessions: "There are {affectedSessions} sessions potentially affected by this change",
      alterPlanNoChanges: "No sessions will be re-planned as part of this change",
      confirmAlterPlan: {
        disable: "Discontinue Measurement Plan & Replan Affected Sessions",
        enable: "Enable Measurement Plan & Replan Affected Sessions"
      },
      alterPlan: {
        disable: "Discontinue Measurement Plan",
        enable: "Enable Measurement Plan"
      },
      alterPlanFor: {
        disable: "Discontinue for",
        enable: "Enable for"
      },
      calculatingReplanningDetails: "Calculating re-planning details",
      supersededPlans: "This protocol is also used at the following levels, which are ignored due to being defined here:",
      alterPlanHeaderText: {
        enable: "",
        disable: ""
      },
      customizeName: "Customize name, or share this protocol with others",
      selectMeasurementPlanForTemplate: "Select a protocol to use as a template",
      chooseDifferentTemplate: "Choose different template",
      step1: "Step 1: Who is being measured?",
      step2: "Step 2: What kind of protocol is it?",
      step3: "Step 3: When are you measuring?",
      step4: "Step 4: What are you measuring?",
      step5: "Step 5: Summary",
      who: "Who:",
      planType: "Plan Type:",
      when: "When:",
      what: "What:",
      pleaseSelectAPlan: "Please select or create a protocol",
      active: "Active:",
      showingPlansForType: "Showing protocols recommended for {planType}.",
      showingAllPlans: "Showing all protocols."
    },
    patientMeasurementPlans: {
      noUpcomingAppointments: "This patient has no upcoming sessions",
      addScaleTitle: "Add single measure",
      addScaleDetails: "to the next session or multiple sessions",
      removeScaleTitle: "Remove single measure",
      removeScaleDetails: "from all active plans for this patient",
      reportTitle: "View patient report",
      addScaleAndReplan: "Confirm measure addition and replan",
      scaleAdded: "Measure Added",
      removeScaleAndReplan: "Confirm measure removal and replan",
      scaleRemoved: "Measure Removed",
      changeMeasurementPlan: "Change protocol",
      changeMeasurementPlanDetails: "choose a different core plan for this patient",
      changeCoreMeasurementPlan: "Change core measurement plan",
      scheduleSession: "Schedule session",
      scheduleAdditionalSessions: "Schedule additional sessions",
      showExampleSchedule: "Show example schedule",
      viewAdvancedSettings: "View Advanced Protocol Settings",
      missingOrganization: "Select a unit above to see what measurement plans would be in use",
      organizationPlaceholder: "Select a unit...",
      providerPlaceholder: "Select a provider..."
    },
    scaleSelector: {
      showingRecommended: "Showing recommended measures",
      showingAll: "Showing all measures"
    },
    coverages: {
      create: {
        title: "New insurance details",
        description: "Add new insurance details"
      },
      activeFrom: "Active from {date}",
      activeUntil: "Active date {date}",
      alwaysActive: "No dates specified",
      coverageUpdated: "Insurance eligibility updated",
      coverageCreated: "Insurance eligibility created"
    },
    conditions: {
      activeFrom: "Active from {date}",
      activeUntil: "Active date {date}",
      alwaysActive: "No dates specified"
    },
    billing: {
      navTitle: "Reimbursement",
      charges: {
        navTitle: "Charges",
        chooseFilters: "Showing charges for...",
        noItems: "There are no items for this charge",
        hideItems: "Hide Items",
        showItems: "Show Items"
      },
      dashboard: {
        awaitingSubmission: "You have {count} claims totalling {amount} awaiting submission.",
        awaitingConfirmation: "You have {count} claims totalling {amount} to confirm as paid."
      },
      sessionCharges: "Reimbursements Details",
      noCharges: "There are no reimbursements associated with this measurement. New charges may take up to an hour to appear after session creation."
    },
    treatmentServices: {
      navTitle: "Treatment Services"
    },
    treatmentTracks: {
      navTitle: "Treatment Tracks"
    },
    organizations: {
      navTitle: "Organizations"
    },
    failedLogins: {
      navTitle: "Failed Logins"
    },
    experiments: {
      show: {
        title: "Experiment Details",
        experimentDisabled: "This experiment is disabled. No new users will be enrolled into it. Users already enrolled will continue to be treated as part of the experiment.",
        reenableExperiment: "Re-enable Experiment",
        disableExperiment: "Disable Experiment",
        noConsentRequired: "No explicit consent required"
      }
    }
  };
});